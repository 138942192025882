import { ActionCreator, EmptyAction, getType, PayloadAction, TypeConstant } from 'typesafe-actions';
import { takeEvery as takeEveryRaw } from './tools/takeEvery';
import { Handler, SagaContext as AnyContext, TakeEveryHandler } from './types';

type ActionBuilder<T extends string, TP extends any = undefined> = [TP] extends [undefined] ? unknown extends TP ? PayloadAction<T, TP> : EmptyAction<T> : PayloadAction<T, TP>

export function getTypedTools<StoreState>() {
  type SagaContext = AnyContext<StoreState>

  function takeEvery<T extends TypeConstant, P = undefined>(actionCreator: (...args: any[]) => ActionBuilder<T, P>, handler: TakeEveryHandler<ActionBuilder<T, P>, P, StoreState>): Handler {
    return takeEveryRaw(getType(actionCreator), handler)
  }

  async function take<T extends TypeConstant, P>(ctx: SagaContext, actionCreator: (...args: any) => PayloadAction<T, P>): Promise<PayloadAction<T, P>>
  async function take<T extends string>(ctx: SagaContext, actionCreator: (...args: any) => EmptyAction<T>): Promise<EmptyAction<T>>
  async function take<T extends TypeConstant, P>(ctx: SagaContext, actionCreator: ActionCreator<T>): Promise<PayloadAction<T, P>> {
    for (; ;) {
      const action = await ctx.takeAny();
      if (action.type === getType(actionCreator)) {
        return action as PayloadAction<T, P>;
      }
    }
  }

  async function waitForState<T>(ctx: SagaContext, func: (state: StoreState) => T): Promise<NonNullable<T>>
  async function waitForState<T>(ctx: SagaContext, func: (state: StoreState) => T, predicate: (value: T) => boolean): Promise<T>
  async function waitForState<T>(ctx: SagaContext, func: (state: StoreState) => T, predicate: (value: T) => boolean = value => Boolean(value)): Promise<T> {
    for (; ;) {
      const res = func(ctx.getState())
      if (predicate(res)) {
        return res
      }
      await ctx.takeAny()
    }
  }

  return {
    take,
    takeEvery,
    waitForState,
  }
}
