import { Action, AnyAction } from 'redux'
import { PayloadAction } from 'typesafe-actions'
import { Handler, SagaContext, SagaContextEx, TakeEveryHandler } from '../types'
import { take } from './take'

export const takeEvery = <P extends any = any, A extends Action = AnyAction, S = any>(type: string, handler: TakeEveryHandler<A, P, S>): Handler<SagaContext<S>> =>
  async (ctx) => {
    while (true) {
      const action = await take(ctx, type)

      const ctxCopy = {
        ...ctx,
        prevState: (ctx as unknown as SagaContextEx).getPrevState(action),
      }

      // don't await (essentially fork)
      handler(ctxCopy, (action as PayloadAction<string, P>).payload, action as A)
    }
  }
