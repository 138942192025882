import { Action, Middleware, MiddlewareAPI } from 'redux'
import { Channel } from './Channel'
import { Handler, SagaContext, SagaContextEx } from './types'

interface SagaMiddleware extends Middleware {
  run(): void
}

export function createSagaMiddleware(rootHandler: Handler) {
  const actionChannel = new Channel<Action>()
  const prevStates = new WeakMap<Action, any>()

  const middleware: Middleware = store => {
    sagaMidlleware.run = () => run(store)

    return next => action => {
      prevStates.set(action, store.getState())
      const result = next(action)
      actionChannel.put(action)
      return result
    }
  }

  const sagaMidlleware: SagaMiddleware = Object.assign(middleware, {
    run() {
      throw new Error('Before running a Saga, you must mount the Saga middleware on the Store using applyMiddleware')
    },
  })

  function run(store: MiddlewareAPI) {
    const ctx: SagaContext & SagaContextEx = {
      ...store,
      getPrevState(action: Action) {
        return prevStates.get(action)
      },
      takeAny() {
        return actionChannel.get()
      },
    }

    rootHandler(ctx).catch(e => console.error('Unhandled rejection', e))
  }

  return sagaMidlleware
}
