export class Channel<M> {
  private promise!: Promise<M>
  private resolve!: (msg: M) => void

  constructor() {
    this.recreate()
  }

  public get() {
    return this.promise
  }

  public put(msg: M) {
    setTimeout(() => {
      this.resolve(msg)
      this.recreate()
    }, 0)
  }

  private recreate() {
    this.promise = new Promise((resolve) => {
      this.resolve = resolve
    })
  }
}
