import { SagaContext } from '../types';

export async function take(ctx: SagaContext, type: string) {
  for (;;) {
    const action = await ctx.takeAny();
    if (action.type === type) {
      return action
    }
  }
}
